import {
	AuthGuard,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Routes,
} from '@angular/router';
import { NavigationTemplateComponent } from '@context/frontend/navigation';
import { CustomAuthGuard, RoleAccessGuard } from '@context/frontend/user';
import { Role } from '@context/shared/types/user';

export const appRoutes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./pages/auth/auth.routes'),
	},
	{
		path: 'invite',
		canActivate: [AuthGuard],
		data: { authGuardPipe: () => redirectLoggedInTo(['home']) },
		loadChildren: () => import('./pages/invite/invite.routes'),
	},
	{
		path: 'external',
		loadChildren: () => import('./pages/external/external.routes'),
	},
	{
		path: '',
		component: NavigationTemplateComponent,
		canActivate: [AuthGuard, CustomAuthGuard],
		data: {
			authGuardPipe: (
				_r: ActivatedRouteSnapshot,
				{ url }: RouterStateSnapshot,
			) =>
				redirectUnauthorizedTo(
					`auth?redirectUrl=${encodeURIComponent(url)}`,
				),
		},
		children: [
			{
				path: 'home',
				loadChildren: () => import('./pages/home/home.routes'),
			},
			{
				path: 'archive',
				loadChildren: () => import('./pages/archive/archive.routes'),
			},
			{
				path: 'profile',
				loadChildren: () => import('./pages/profile/profile.routes'),
			},
			{
				path: 'notifications',
				loadChildren: () =>
					import('./pages/notifications/notifications.routes'),
			},
			{
				path: 'users',
				loadChildren: () => import('./pages/users/users.routes'),
				canActivate: [RoleAccessGuard],
				data: { role: 'admin' as Role },
			},
			{
				redirectTo: 'home',
				path: '**',
			},
		],
	},
	{
		redirectTo: 'home',
		path: '**',
	},
];
