import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

// Call the element loader before the render call
defineCustomElements(window);

// services to use while in production
if (environment.production) {
	enableProdMode();
	if (environment.sentry) sentry.init({ dsn: environment.sentry.dsn });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
	console.error(err),
);
