import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ContentService } from '@context/frontend/content';
import { getUaDevice } from '@context/frontend/native/platform';
import { TuiAlert, TuiDialog, TuiRoot } from '@taiga-ui/core';
import { filter } from 'rxjs';

@Component({
	standalone: true,
	imports: [TuiRoot, RouterModule, TuiAlert, TuiDialog],
	selector: 'ctx-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	private readonly router = inject(Router);
	private readonly contentService = inject(ContentService);

	ngOnInit() {
		this.router.events
			.pipe(filter((value) => value instanceof NavigationEnd))
			.subscribe((value) => this.onRouterEnd(value));
	}

	onRouterEnd(value: NavigationEnd) {
		this.determineOpenFolder(value.url);
	}

	determineOpenFolder(url: string) {
		let folderId = null;

		// first index is an empty string
		const path = url.split('/').slice(1);
		if (path[1] === 'folder' && typeof path[2] === 'string') {
			folderId = path[2];
		}

		this.contentService.setOpenFolder(folderId);
	}
}
