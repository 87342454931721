<tui-root>
	<!-- content of your app -->
	<router-outlet />

	<!-- If you need, you can add something between Taiga portal layers: -->
	<!-- <ng-container ngProjectAs="tuiOverContent"></ng-container>
	<ng-container ngProjectAs="tuiOverDialogs"></ng-container>
	<ng-container ngProjectAs="tuiOverAlerts"></ng-container>
	<ng-container ngProjectAs="tuiOverPortals"></ng-container>
	<ng-container ngProjectAs="tuiOverHints"></ng-container> -->
</tui-root>
