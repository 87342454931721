<tui-root>
	<router-outlet />
</tui-root>

<div
	id="app-splash-screen"
	class="app_splash-screen"
	[hidden]="(splash.visible$ | async) === false"
	[class.visible]="splash.visible$ | async"
>
	<img src="assets/images/logo-white.png" />
	<tui-loader [inheritColor]="true" size="l" />
</div>
